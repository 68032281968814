import './RoadmapCredits.css';

import RoadmapCredit from './RoadmapCredit/RoadmapCredit';

const RoadmapCredits = ({ creditsData }) => {
  return (
    <div className='credits-container'>
      <h1 className='title'>Credits</h1>

      {creditsData.map(credit => (
        <RoadmapCredit key={credit.name} credit={credit} />
      ))}
    </div>
  )
}

export default RoadmapCredits;