import { Fragment } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';

import ScrollToTop from './components/Globals/ScrollToTop/ScrollToTop';
import Banner from './components/Banner/Banner'
import Body from './components/Body/Body'
import MainPage from './components/MainPage/MainPage';
import ContactPage from './components/ContactPage/ContactPage';
import PageData from './components/Globals/PageData/PageData';

import RoadmapPage from './components/RoadmapComponents/RoadmapPage/RoadmapPage';

import { roadmapsData } from './Data/Data';

const App = () => {

  return (
    <Fragment>
      <ScrollToTop />

      <Banner />

      <Body>
        <Routes>
          <Route exact path="/" element={
            <PageData title='OwlStudent'>
              <MainPage />
            </PageData>
          } />

          {roadmapsData.map(roadmapData => (
            <Route exact key={roadmapData.pathBase} path={roadmapData.pathBase} element={
              <PageData 
                title={`${roadmapData.title} Courses Roadmap: Zero to Advanced - OwlStudent`}
                description={roadmapData.description}
              >
                <RoadmapPage roadmapData={roadmapData} />
              </PageData>
            } />
          ))}

          <Route exact path="/contact" element={
            <PageData title='Contact - OwlStudent'>
              <ContactPage />
            </PageData>
          } />

          <Route
              path="*"
              element={<Navigate to="/" replace />}
          />
        </Routes>
      </Body>
    </Fragment>
  );
}

export default App;
