import './Button.css';

const Button = ({ onClick, label, width, height }) => {
  return (
    <div className="button-container">
      <button 
        className="button" 
        onClick={onClick}
        style={{ width, height }}
      >
        {label}
      </button>
    </div>
  );
}

export default Button;