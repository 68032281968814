import { Fragment } from "react";

import PresentationText from './PresentationText/PresentationText'
import RoadmapOptions from "../RoadmapComponents/RoadmapOptions/RoadmapOptions";
import EmailSubscription from "../Utils/EmailSubscription/EmailSubscription";

import { tags, roadmapsData } from "../../Data/Data";

const MainPage = () => {
  return (
    <Fragment>
      <PresentationText />

      <RoadmapOptions isLink roadmapsData={roadmapsData} />
      
      <h2 className="normal-text subscribe-desc">Get mails for new roadmaps and learning tips! No spam, you can unsubscribe later.</h2>

      <EmailSubscription title='Subscribe to the Mail List' tagID={tags.general} />

    </Fragment>
  )
}

export default MainPage;