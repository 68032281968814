import RoadmapSection from "../../RoadmapSection/RoadmapSection";
import EmailSubscription from "../../../Utils/EmailSubscription/EmailSubscription";
import RoadmapCredits from "../../RoadmapCredits/RoadmapCredits";

import { tags } from "../../../../Data/Data";

import './RoadmapList.css';

const RoadmapList = ({ roadmapData: { courses, credits, title } }) => {
  return (
    <div className="roadmap-container">

      {courses.map(course => (
        <RoadmapSection 
          coursePortrait={course.portrait} 
          courseLink={course.link}
          title={course.title}
          key={course.title}
        >
          {course.description}
        </RoadmapSection>
      ))}

      {credits.length > 0 && <RoadmapCredits creditsData={credits}/>}

      <h2 className="normal-text subscribe-desc">Get mails for new roadmaps and learning tips! No spam, you can unsubscribe later.</h2>

      <EmailSubscription title={`Subscribe to the Mail List`} tagID={tags.general} />
    </div>
  );
}

export default RoadmapList;