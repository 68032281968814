import webDev from './WebDevData';
import gameDev from './GameDevData';

export const tags = {
  general: '2980687',
  webDev: '2980685'
}

export const roadmapsData = [
  gameDev,
  webDev
]