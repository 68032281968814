import './RoadmapCredit.css';

const RoadmapCredit = ({ credit: { profilePic, name, description, links } }) => (
  <div className='credit-container'>
    <img src={profilePic} className='credit-profile' alt={name + ' Profile'} />

    <div className='credit-info'>
      <h2 className='credit-name'>{name}</h2>

      <p className='normal-text'>{description}</p>

      <div className='credit-links'>
        {links.map(link => (
          <div key={link.title} className='credit-link'> 
            <img src={link.icon} alt='Link Icon' />
          
            <a href={link.link} target="_blank" rel="noreferrer">{link.title}</a>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default RoadmapCredit