import './EmailSubscription.css';

const EmailSubscription = ({ title, tagID }) => {
  return (
    <div className="email-form-container">
      <div id="mc_embed_signup">
        <form action="https://gmail.us21.list-manage.com/subscribe/post?u=9eca8b2c7b84dcc16b4f06be9&amp;id=2e1c0f507b&amp;f_id=007afbe6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
          <div id="mc_embed_signup_scroll"><h2>{title}</h2>
              <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
              <div className="mc-field-group"><label htmlFor="mce-EMAIL">Email <span className="asterisk">*</span></label><input defaultValue="" type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" /><span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span></div>
          <div hidden=""><input type="hidden" name="tags" value={tagID} /></div>
          <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
              <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
          </div>
          <div aria-hidden="true" style={{ position: 'absolute', left: -5000 + 'px' }}>
              <input defaultValue="" type="text" name="b_9eca8b2c7b84dcc16b4f06be9_2e1c0f507b" tabIndex="-1" />
          </div>
              <div className="optionalParent">
                  <div className="clear foot">
                      <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                      <p style={{margin: 0 + 'px auto' }}><a href="http://eepurl.com/iIrmSA" title="Mailchimp: marketing por correo electrónico fácil y divertido"><span style={{ display: 'inline-block', backgroundColor: 'transparent', borderRadius: 4 + 'px' }}>
                      <img className="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-light.svg" alt="Intuit Mailchimp" style={{ width: 220 + 'px', height: 40 + 'px', display: 'flex', padding: 2 + 'px' + 0 + 'px', justifyContent: 'center', alignItems: 'center' }} /></span></a></p>
                  </div>
              </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EmailSubscription;