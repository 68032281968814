import TextCollapsible from '../../../Utils/TextCollapsible/TextCollapsible';

import './RoadmapPresentationText.css';

const RoadmapPresentationText = ({ roadmapData }) => {
  return roadmapData.presentationText.map(textInfo => {
    if(textInfo.type === 'icon-text') {
      return (
        <div className="presentation-definition" key={JSON.stringify(textInfo)}>
          <img src={roadmapData.icon} alt={roadmapData.title + " Courses"} />

          <p className="normal-text presentation-text">
            {textInfo.text}
          </p>  
        </div>
      )
    }

    if(textInfo.type === 'subtitle') {
      return (
        <h2 className='subtitle' key={JSON.stringify(textInfo)}>{textInfo.text}</h2>
      )
    }

    if(textInfo.type === 'normal-text') {
      return (
        <p className='normal-text presentation-text' key={JSON.stringify(textInfo)}>
          {textInfo.text}
        </p>
      )
    }

    if(textInfo.type === 'collapsible') {
      return (
        <TextCollapsible title={textInfo.title} key={JSON.stringify(textInfo)}>
          <div className='normal-text presentation-text'>
            {textInfo.text}
          </div>
        </TextCollapsible>
      )
    }

    return null;
  })
}

export default RoadmapPresentationText;