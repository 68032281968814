import { Link } from 'react-router-dom'

import './RoadmapOption.css'

const RoadmapOption = ({ icon, name, onClick, isLink, target, isSelected= false }) => {
  return isLink ? (
    <Link 
      className='option-container' 
      to={target} 
    >
      <img src={icon} alt={name + ' Icon'} />
      <p>{name}</p>
    </Link>
  ) :
  (
    <button 
      className='option-container' 
      onClick={onClick} 
      style={isSelected ? { borderColor: '#00FFF5' } : {}}
    >
      <img src={icon} alt={name + ' Icon'} />
      <p>{name}</p>
    </button>
  )
}

export default RoadmapOption;