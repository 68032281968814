import { useEffect } from "react";
import {Helmet} from "react-helmet-async";

const PageData = ({ title, description, children }) => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      {children}
    </>
  );
}

export default PageData;