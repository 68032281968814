import webDevIcon from '../assets/WebDev/web-development-courses.png';

import htmlCssPortrait from '../assets/WebDev/HTMLCSS-FullCourse.jpg';
import javascriptPortrait from '../assets/WebDev/TheCompleteJavaScriptCourse2024-ZeroToExpert.jpg';
import reactPortrait from '../assets/WebDev/React-TheCompleteGuide2024.jpg';
import advancedCssSass from '../assets/WebDev/advanced-css-sass.jpg';
import nextJS from '../assets/WebDev/Next.js14AndReact.jpg';
import nodePortrait from '../assets/WebDev/Node.jsExpressMongoDBAndMore.jpg'

import linkIcon from '../assets/link-icon.png';

const webDevData = {
  title: 'Web Development',
  description: 'Become an advanced web developer from total beginner with a FREE guide that includes the best courses on the Internet. Learn and master HTML, CSS, JavaScript, Re...',
  nickname: 'Web Dev',
  isNested: false,
  icon: webDevIcon,
  pathBase: '/courses/web-development-courses',
  presentationText: [
    {
      type: 'icon-text',
      text: 'Web development is a discipline that involves all the tasks that are required to create, program and mantain a website.'
    },
    {
      type: 'subtitle',
      text: 'Presentation'
    },
    {
      type: 'normal-text',
      text: "Click on the topics to check the ones you have questions to be up and ready to learn this subject!"
    },
    {
      type: 'collapsible',
      title: 'Frontend and backend',
      text: (
        <>
          <p className='normal-text presentation-text'>
            Frontend is everything you see on a website (a.k.a the user interface). Everything that you can see and interact on the website is frontend. The colors, the position of the text, the images, decoration, what happens when you press a button, etc. All of that is programmed in the frontend!
          </p>
          <p className='normal-text presentation-text'>
            Backend refers to everything around databases. Storing data from the website to them, modifying saved data and ultimately setting the connection with the frontend so it can display the information with the users. It also is responsible of connection with external websites, servers or databases! Things like managing accounts, payments, online chats, notifications, etc are backend programming tasks!
          </p>
        </>
      )
    },
    {
      type: 'collapsible',
      title: 'Roadmap Info',
      text: "In this roadmap/path, we'll start with frontend and end with backend with a list of web development courses online and for beginners. At the end you are going to become a fullstack developer (someone that develops both frontend and backend) and a certified web developer!"
    }
  ],
  nestedRoadmaps: [],
  courses: [
    {
      portrait: htmlCssPortrait,
      title: '1. HTML & CSS Full Course - Beginner to Pro',
      link: 'https://www.youtube.com/watch?v=G3e-cpL7ofc',
      description: "HTML and CSS are the single most important languages of the whole Internet. But don't worry! Learning HTML and CSS is an easy task. In this tutorial you'll learn step by step these two languages and end ready to go for the next web dev course!"
    },
    {
      portrait: javascriptPortrait,
      title: '2. The Complete JavaScript Course 2024: From Zero to Expert!',
      link: 'https://www.udemy.com/course/the-complete-javascript-course/',
      description: "Great job! As you have seen HTML and CSS are responsible for the structure and styling of your website. Well, JavaScript is responsible of the logic! Basically, learn JavaScript and give steroids for your websites! JavaScript is a total must. But don't worry! It's a super friendly programming language and with this amazing course it's going to get really fun!"
    },
    {
      portrait: reactPortrait,
      title: '3. React - The Complete Guide 2024 (incl. React Router & Redux)',
      link: 'https://www.udemy.com/course/react-the-complete-guide-incl-redux/',
      description: "Congratulations! You can call yourself a web developer now. Now we'll learn one of the most powerful tools you can add to your skillset: React. This is a Javascript 'library', which basically gives some powers to Javascript to make your coding much easier and effective. Althought there are many web development courses based on React, this is one of the best!"
    },
    {
      portrait: advancedCssSass,
      title: '4. Advanced CSS and Sass: Flexbox, Grid, Animations and More!',
      link: 'https://www.udemy.com/course/advanced-css-and-sass/',
      description: "Wow! You are doing fabulous! Now, to be an actual master you need to perfect the skills you already know. One of the most important to perfect is CSS and responsive design. One of the key diferences between good web devs and amazing web devs is their ability to make good-looking websites. In this course you'll master this and styling will never be a problem to you."
    },
    {
      portrait: nextJS,
      title: '5. Next.js 14 & React - The Complete Guide',
      link: 'https://www.udemy.com/course/nextjs-react-the-complete-guide/',
      description: "We are really proud of you! There's one last step for you to be a real expert in frontend web development and that is Next.js, a React framework. In this course you'll start getting deep in backend concepts but don't worry, you are ready for it and this will prepare you for the backend course!"
    },
    {
      portrait: nodePortrait,
      title: '6. Node.js, Express, MongoDB & More: The Complete Bootcamp 2024',
      link: 'https://www.udemy.com/course/nodejs-express-mongodb-bootcamp/',
      description: "This is it! A full dive into coding backend with Node.js, after this course you'll be able to program your own servers and manage your own databases. With this you are a certified fullstack web developer!"
    }
  ],
  credits: []
}

export default webDevData;