import gameDevIcon from '../assets/GameDev/game-dev-icon.png';

import gameDev2D from './GameDev2DData';
import gameDev3D from './GameDev3DData';

const gameDevData = {
  title: 'Game Development',
  description: 'Become an advanced 2D/3D game developer from total beginner and make your dream game. Access the FREE guide with the best courses on the internet. We will learn...',
  nickname: 'Game Dev',
  isNested: false,
  icon: gameDevIcon,
  pathBase: '/courses/game-development-courses',
  presentationText: [
    {
      type: 'icon-text',
      text: 'Game development is the process for creating videogames. Using different skills to bring an idea to a videogame.'
    },
    {
      type: 'subtitle',
      text: 'Presentation'
    },
    {
      type: 'normal-text',
      text: "Click on the topics to check the ones you have questions to be up and ready to learn this subject!"
    },
    {
      type: 'collapsible',
      title: '2D or 3D?',
      text: (
        <>
          <p className='normal-text presentation-text'>
            There are two main perspectives from which you can build a game: 2D and 3D. 2D games are the ones that have flat graphics (like Super Mario Bros, Hollow Knight or Stardew Valley!). 3D games are the ones that have 3D models (like Call of Duty, Resident Evil or Fortnite!).
          </p>

          <p className='normal-text presentation-text'>
            The process of building your game will vary depending if it's 2D or 3D, and althought the difference is not that big it can be better to learn in the one where you want to build most of your games. That's why we made two roadmaps, one for each perspective.
          </p>
        </>
      )
    },
    {
      type: 'collapsible',
      title: 'Game Engines',
      text: "Game Engines are the programs that you use to make your game and each one has its own characteristics. There is Unreal, Unity, Godot and much more! But don't worry, all of them will let you achieve your purpose at the end. For these roadmaps, we will focus on Unity, one of the most popular and reliable game engines out there!"
    },
    {
      type: 'collapsible',
      title: 'Start Small',
      text: "The most important thing to understand in game dev! The first projects you make need to be as small as possible. Make the smallest and simplest games possible first. This will give you confidence to gradually start to make more complex games. Always keep this in mind!"
    },
    {
      type: 'collapsible',
      title: 'Roadmaps Info',
      text: "Each of these roadmaps will bring you from zero to advanced. At the end of these you will be able to build your own games confidently and be a great game developer! If you are unsure which to decide, don't worry too much, once you learn game dev on one perspective it's going to be really easy to change to the other. For now just choose the one that you feel better with!"
    },
    {
      type: 'normal-text',
      text: 'Click on one of the roadmaps to go to it.'
    }
  ],
  nestedRoadmaps: [
    gameDev2D,
    gameDev3D
  ],
  courses: [],
  credits: []
}

export default gameDevData;