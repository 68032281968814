import './RoadmapSection.css';

const RoadmapSection = ({ coursePortrait, courseLink, title, children }) => {
  return (
    <div className="roadmap-section-container">
      { coursePortrait && 
        <div className='roadmap-course-portrait'>
          <img src={coursePortrait} alt={title} />

          <a href={courseLink} target="_blank" rel="noreferrer">Go to Course</a>
        </div>
      }

      <div className='roadmap-course-info' style={coursePortrait ? {} : { width: 100 + '%', maxWidth: 100 + '%' }}>
        <h2 className='subtitle'>{title}</h2>

        <p className='normal-text'>{children}</p>
      </div>
    </div>
  )
}

export default RoadmapSection;