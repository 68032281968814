import { useState, useRef, useEffect } from 'react';

import Button from '../../Utils/Button/Button';
import RoadmapOptions from '../RoadmapOptions/RoadmapOptions';
import RoadmapPresentationText from './RoadmapPresentationText/RoadmapPresentationText';
import RoadmapList from './RoadmapList/RoadmapList';

import './RoadmapPage.css';

const RoadmapPage = ({ roadmapData }) => {
  const [ showRoadmap, setShowRoadmap ] = useState(false);
  const [ selectedRoadmapName, setSelectedRoadmapName ] = useState('')

  const titleRef = useRef(null);

  const showSelectedRoadmap = (roadmapTitle) => {
    setShowRoadmap(true);
    setSelectedRoadmapName(roadmapTitle);
  }

  const hideRoadmap = () => {
    setShowRoadmap(false);
    setSelectedRoadmapName('');
  }

  useEffect(() => {
    if(showRoadmap) {
      titleRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
    }
  }, [showRoadmap])

  return(
    <>
      <div className="presentation-container">
        <h1 className='title'>{roadmapData.title} Courses</h1>

        <RoadmapPresentationText roadmapData={roadmapData} />

        {
          roadmapData.nestedRoadmaps.length > 0 ?
            <RoadmapOptions
              selectedRoadmapName={selectedRoadmapName}
              showSelectedRoadmap={showSelectedRoadmap} 
              roadmapsData={roadmapData.nestedRoadmaps} 
            />
          :
            <Button 
              onClick={showRoadmap ? hideRoadmap : () => showSelectedRoadmap(roadmapData.title)}
              label={showRoadmap ? 'Hide Roadmap' : 'Show Roadmap'}
              width={200 + 'px'} 
              height={70 + 'px'} 
            />
        }
      </div>

      <h1 
        style={{ display: showRoadmap ? 'block' : 'none' }} 
        ref={titleRef} 
        className="title"
      >
        Learn {selectedRoadmapName} Roadmap
      </h1>

      {
        roadmapData.nestedRoadmaps.length > 0 ? 
          roadmapData.nestedRoadmaps.map(roadmap => (
            <div key={roadmap.title} style={{ display: showRoadmap && selectedRoadmapName === roadmap.title ? 'block' : 'none' }}>
              <RoadmapList roadmapData={roadmap} />
            </div>
          ))
        :
        <div style={{ display: showRoadmap ? 'block' : 'none' }}>
          <RoadmapList roadmapData={roadmapData} />
        </div>
      }
    </>
  )
}

export default RoadmapPage;