import { Link } from 'react-router-dom';

import logo from '../../assets/logo.png';

import './Banner.css';

const Banner = () => {
  return (
    <Link className='banner-container' to='/'>
      <img src={logo} alt='Logo' className='banner-logo' />
    </Link>
  )
}

export default Banner;

