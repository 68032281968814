import Collapsible from "react-collapsible";

import './TextCollapsible.css';

const TextCollapsible = ({ title, children }) => (
  <Collapsible trigger={title} triggerTagName="h2">
    {children}
  </Collapsible>
)

export default TextCollapsible;