import RoadmapOption from './RoadmapOption/RoadmapOption'

import './RoadmapOptions.css'

const RoadmapOptions = ({ roadmapsData, selectedRoadmapName, showSelectedRoadmap, isLink = false }) => {
  return (
    <div className="options-container">
      {roadmapsData.map(roadmap => (
        <RoadmapOption 
          key={roadmap.nickname}
          icon={roadmap.icon} 
          name={roadmap.nickname} 
          isLink={isLink}
          target={roadmap.pathBase}
          onClick={() => showSelectedRoadmap(roadmap.title)}
          isSelected={!isLink && roadmap.title === selectedRoadmapName}
        />
      ))}
    </div>
  )
}

export default RoadmapOptions;