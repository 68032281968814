import './PresentationText.css';

const PresentationText = () => {
  return (
    <div className='presentationText-container normal-text'>
      <p>Learning something new shouldn't be that difficult!</p>
      <p>We guide you from total zero to advanced with a crystal clear roadmap.</p>
      <p>What do you want to learn?</p>
    </div>
  )
}

export default PresentationText;