import icon3dGameDev from '../assets/GameDev/game-dev-3d-icon.png';
import linkIcon from '../assets/link-icon.png';
import discordIcon from '../assets/discord-icon.png';

import howToMakeAGamePortrait from '../assets/GameDev/Brackeys-How-To-Game.jpg';
import cSharpTenMinutes from '../assets/GameDev/csharp-10-minutes.jpg';
import unity3dCourse from '../assets/GameDev/complete-unity-3d-course.jpg';
import blenderCourse from '../assets/GameDev/blender-tutorial.jpg';
import uiToolkitCourse from '../assets/GameDev/modern-unity-ui.jpg';

import pyxidusProfile from '../assets/CreditsProfiles/Pyxidus-Synthetic-Games.webp';

const gameDev3DData = {
  title: 'Game Development 3D',
  nickname: '3D',
  isNested: true,
  icon: icon3dGameDev,
  pathBase: '/courses/game-development-courses/3d',
  presentationText: [],
  nestedRoadmaps: [],
  courses: [
    {
      portrait: howToMakeAGamePortrait,
      title: '1. How to make a game?',
      link: 'https://www.youtube.com/playlist?list=PLPV2KyIb3jR53Jce9hP7G5xC4O9AgnOuL',
      description: "To start, we will get a brief introduction of Unity and most of the things you can do with it. In this really beginner friendly you will get comfortable with Unity and create your first game!"
    },
    {
      portrait: cSharpTenMinutes,
      title: '2. Learn C# BASICS in 10 MINUTES!',
      link: 'https://www.youtube.com/watch?v=IFayQioG71A',
      description: "Now that we have a basic understanding of Unity, we need a basic understanding of its programming language: C#! In this tutorial we will have a quick dive on it. Be sure to make some twists and experiment with the code so you can truly understand what each line does!"
    },
    {
      portrait: unity3dCourse,
      title: '3. Complete C# Unity Game Developer 3D',
      link: 'https://www.udemy.com/course/unitycourse2/',
      description: "It's the time to get deep into Unity and C# and start making games! In this course you'll make many games of all different kinds so that you can see all the important features of Unity and how they can help you make your own games. After this course, you can call yourself a Unity 3D Developer!"
    },
    {
      portrait: blenderCourse,
      title: '4. Complete Blender Creator: Learn 3D Modelling for Beginners',
      link: 'https://www.udemy.com/course/blendertutorial/',
      description: "Of course, one crucial part of 3D games are 3D models. In this course you'll master 3D modeling with a really powerful tool called Blender. This way you'll be able to run your imagination and create the characters and environments that you want for your game!"
    },
    {
      portrait: uiToolkitCourse,
      title: '5. Modern Unity UI with UI Toolkit',
      link: 'https://www.udemy.com/course/modern-unity-ui-with-ui-toolkit/',
      description: "Great! We are almost there! Now, UI is one of the things that if done correctly can be the difference between a good game and a great game! In this course you will learn the new UI system that Unity offers that will make your design and coding much easier!"
    },
    {
      portrait: null,
      title: '6. Make 10 tiny games and publish them',
      link: null,
      description: "Last challenge! Make 10 really small games and publish them in places like itch.io! Let's enfasize the word 'small' here. Make them of different topics and don't overcomplicate! This is super crucial. Once you finish them try to make some slightly more complex games and keep going until you feel really prepared to make your own big games!"
    }
  ],
  credits: [
    {
      profilePic: pyxidusProfile,
      name: 'Pyxidus from Synthetic Games',
      description: "Game developer with 4 years of experience. Learnt everything in the web without external help. Owner of Synthetic Games and creator of the game 'Yummy Ham'!",
      links: [
        { 
          icon: discordIcon,
          title: 'Discord',
          link: 'https://discordapp.com/users/282971961355927552'
        },
        { 
          icon: linkIcon,
          title: 'Yummy Ham',
          link: 'https://play.google.com/store/apps/details?id=com.SyntheticGames.YummyHam'
        }
      ]
    }
  ]
}

export default gameDev3DData;